<template>
  <div class="mainFooter">
    <div class="footer">
      <div class="footer__wrap">
        <div class="footer__wrap_menu">
          <div
            v-for="(el, index) in footer"
            :key="index"
            class="footer__wrap_menu-column"
          >
            <div class="footer__wrap_menu-elems">
              <p class="footer__wrap_menu-elems-title">{{ el.title }}</p>
              <a
                v-for="(link, jindex) in el.items"
                :key="jindex"
                @click="checkSection($event, link)"
                :href="link.link"
                ><p class="darkText link">{{ link.name }}</p></a
              >
            </div>
          </div>
        </div>

        <div class="infoWrap" v-if="filteredContacts?.length">
          <a :href="'tel:' + filteredContacts[0].text"
            ><p class="phoneNumber">{{ filteredContacts[0].text }}</p></a
          >
          <a :href="'mailto:' + filteredContacts[1].text"
            ><p class="mail">{{ filteredContacts[1].text }}</p></a
          >
          <div class="wrapDiv">
            <a href="https://www.instagram.com/franklinsburger">
              <img src="../assets/inst_icon.png" class="wrapIcon link" />
            </a>

            <a href="https://vk.com/franklinsburgerrussia">
              <img src="../assets/vk_icon.png" class="wrapIcon link" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer__wrap footer__wrap--bottom">
        <div class="footer__wrap_menu">
          <div class="footer__wrap_menu-row bigText">
            <a href="/restaurants">
              <div
                class="footer__wrap_menu-column footer__wrap_menu-column--left bigText"
              >
                <div
                  v-if="MoscowPoints?.length"
                  class="footer__wrap_menu-elems"
                >
                  {{ MoscowPoints.length }} {{ MoscowPoints.word }}
                </div>
                <div
                  class="footer__wrap_menu-elems darkText"
                  style="Line height:23.58px;"
                >
                  В Москве
                </div>
              </div>
            </a>

            <a href="/restaurants#Petersburg">
              <div
                class="footer__wrap_menu-column footer__wrap_menu-column--left bigText"
              >
                <div v-if="OtherPoints?.length" class="footer__wrap_menu-elems">
                  {{ OtherPoints.length }} {{ OtherPoints.word }}
                </div>
                <div
                  class="footer__wrap_menu-elems darkText"
                  style="Line height:23.58px;"
                >
                  Регионы
                </div>
              </div>
            </a>

            <!--   <a href="/restaurants#sochi">
                        <div  class="footer__wrap_menu-column bigText">
                            <div v-if="SochiPoints?.length"  class="footer__wrap_menu-elems">
                                {{SochiPoints.length}} {{SochiPoints.word}}
                            </div>
                            <div class="footer__wrap_menu-elems darkText" style="Line height:23.58px;">
                               В Сочи
                            </div>
                        </div>
                    </a> -->

            <!-- <img @click="openMap" class="footer__wrap_map" src="@/assets/map_icon.svg"> -->
          </div>
        </div>
        <div class="container">
          <div class="twogis">
            <a
              href="https://info.2gis.ru/moscow/company/news/polzovateli-2gis-opredelili-tysyachu-luchshih-zavedeniy-obshchepita-v-gorodah-rossii"
            >
              <img src="../assets/sticke2gis.png" class="twogis" />
            </a>
          </div>
          <div class="infoWrap">
            <a href="https://www.delivery-club.ru/srv/FRANKLINS_BURGJeR">
              <img class="bigIcon" src="../assets/delivery.png" />
            </a>

            <a
              class="bigIcon"
              href="https://eda.yandex.ru/moscow/r/franklins_burger?placeSlug=franklins_burger_skhodnenskaya"
            >
              <img class="bigIcon" src="../assets/yandex.png" />
            </a>
            <a class="bigIcon" href="https://sbermarket.ru/franklinsburger">
              <img class="bigIcon" src="../assets/sbermarket.png" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <hr class="footer__line" />
    </div>
    <div class="footer bootomTextFooter">
      <div class="bootomTextFooter__section">©2012 "Франклинc Бургер"</div>
      <div class="bootomTextFooter__section">
        <a
          href="КБЖУ.pdf"
          target="_blank"
          class="bootomTextFooter-link"
          style="margin-left: 6px; color: #c2c2c2; white-space: nowrap"
          >КБЖУ</a
        >
        <a
          href="Состав.pdf"
          target="_blank"
          class="bootomTextFooter-link"
          style="margin-left: 6px; color: #c2c2c2; white-space: nowrap"
          >Подробный состав продукции</a
        >
        <a
          href="Политика_обработки_персональных_данных.pdf"
          target="_blank"
          class="bootomTextFooter-link"
          style="margin-left: 6px; color: #c2c2c2; white-space: nowrap"
          >Все права защищены</a
        >
      </div>
    </div>

    <div class="footer mobileTextFooter">
      <section class="mobileTextFooter-section">
        <a
          href="КБЖУ.pdf"
          target="_blank"
          class="bootomTextFooter-link bootomTextFooter-link--white"
          style="margin-left: 6px; color: #c2c2c2; white-space: nowrap"
          >КБЖУ</a
        >
        <a
          href="Состав.pdf"
          target="_blank"
          class="bootomTextFooter-link bootomTextFooter-link--white"
          style="margin-left: 6px; color: #c2c2c2; white-space: nowrap"
          >Подробный состав продукции</a
        >
      </section>
      <section class="mobileTextFooter-section">
        ©2012 "Франклинc Бургер"
        <a
          href="Политика_обработки_персональных_данных.pdf"
          target="_blank"
          class="bootomTextFooter-link"
          style="margin-left: 6px; color: #c2c2c2; white-space: nowrap"
          >Все права защищены</a
        >
      </section>
    </div>
  </div>
  <map-app v-if="isOpenMap" @closeMap="closeMap" />
</template>
<script>
import { useContacts } from "@/composition/useContacts.js";
import { useSalePoints } from "@/composition/useSalePoints.js";
import { onMounted } from "@vue/runtime-core";
import { ref } from "vue";
import MapApp from "@/components/Restaurants/MapApp.vue";
import { log } from "util";

export default {
  data() {
    return {
      footer: [
        {
          title: "Франклинс Бургер",
          items: [
            { name: "О нас", link: "/about" },
            { name: "Руководство", link: "/about#chiefs", section: "managers" },
            { name: "Рестораны", link: "/restaurants" },
            { name: "Отзывы", link: "/reviews" },
          ],
        },
        {
          title: "Работа у нас",
          items: [{ name: "Карьера", link: "/career" }],
        },

        // {
        //         title: "Специально для вас",
        //         items: [
        //             {name: 'Спецпредложения', link: '/offers'},
        //         ]

        // }
      ],
      isOpenMap: false,
    };
  },
  components: {
    MapApp,
  },
  methods: {
    openMap() {
      this.isOpenMap = true;
    },
    closeMap() {
      this.isOpenMap = false;
    },
    async checkSection(event, link) {
      if (link.section) {
        event.preventDefault();
        await this.$router.push("/about");
        this.$emit("clickCategory", "Руководство");
        console.log(this.$route.path);
      }
    },
  },
  setup() {
    //////console.log("0101");
    const { getContacts, contacts } = useContacts();
    const { getPoints, points } = useSalePoints();
    const filteredContacts = ref(null);
    const MoscowPoints = ref(null);
    // const PiterPoints = ref(null);
    const OtherPoints = ref(null);
    const Cities = ref([]);

    const declOfNum = (n, text_forms) => {
      n = Math.abs(n) % 100;
      var n1 = n % 10;
      if (n > 10 && n < 20) {
        return text_forms[2];
      }
      if (n1 > 1 && n1 < 5) {
        return text_forms[1];
      }
      if (n1 == 1) {
        return text_forms[0];
      }
      return text_forms[2];
    };

    onMounted(() => {
      getContacts().then(() => {
        filteredContacts.value = contacts.value.filter((c) => {
          return c.name === "Центральный офис";
        });
      });
      // //////console.log(contacts);
      getPoints().then(() => {
        // console.log(points.value);

        MoscowPoints.value = points.value.filter(
          (el) => el.city.name == "Москва"
        );
        OtherPoints.value = points.value.filter(
          (el) => el.city.name !== "Москва"
        );
        // SochiPoints.value = points.value.filter(el => el.city.name == "Сочи")
        OtherPoints.value.word = declOfNum(OtherPoints.value.length, [
          "ресторан",
          "ресторана",
          "ресторанов",
        ]);
        MoscowPoints.value.word = declOfNum(MoscowPoints.value.length, [
          "ресторан",
          "ресторана",
          "ресторанов",
        ]);
        // SochiPoints.value.word = declOfNum(SochiPoints.value.length, ['ресторан', 'ресторана', 'ресторанов'])

        points.value.forEach((element) => {
          if (!Cities.value.includes(element.city.name)) {
            Cities.value.push(element.city.name);
          }
          // cityList.value.push(element)
        });
        Cities.value = Cities.value.map((city) => {
          let name = "";
          if (city == "Москва") {
            name = "В Москве";
          } else if (city == "Санкт-Петербург") {
            name = "В Санкт-Петербурге";
          } else {
            name = `В г. ${city}`;
          }

          let length = points.value.filter((el) => el.city.name == city).length;
          let word = declOfNum(length, ["ресторан", "ресторана", "ресторанов"]);
          return { name: name, length: length, word: word };
        });
        console.log(Cities.value);
      });
    });
    return {
      contacts,
      filteredContacts,
      points,
      MoscowPoints,
      OtherPoints,
      // SochiPoints
    };
  },
};
</script>

<style lang="scss" src="@/style/footer.scss"></style>
